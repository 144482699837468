html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: $fuente_principal;
    font-size: 1.6rem;
    line-height: 1.8;

}



p {
    color: $negro;
    font-size: 2rem;
}
.contenedor {
    width: 95%;
    max-width: 120rem;
    margin: 0 auto;
}

a {
    text-decoration: none;
}

img,
picture {
    padding: 1rem;
    width: 100%;
    display: block;
}

h1, h2, h3 {
    font-family: $fuente_principal;
    margin: calc($separacion / 2) 0;
    font-weight: 300;
    text-align: center;
    
}

h1 {
    font-size: 3.8rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.6rem;
}