.logo{
    width:30rem;
    margin-left: 1rem;
    margin-right: 1rem;
    
  
}

.derecha{
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet{
        align-items: flex-end;
    }
    .dark-mode-boton{
        width: 5rem;
        margin-top: 3rem;
        margin-bottom: 2rem;
        filter:invert(100%);//invierte el color del objeto

        &:hover{
            cursor: pointer;
        }

        @include tablet{
            margin-top: 0;
        }
    }
}
.barra{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: .5rem;
    
    @include tablet{
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        .logo{
            width:30rem;
            margin-left: 8rem;
            margin-right: 3rem;
        }

    }
}
.navegacion{
    
    visibility: hidden; //oculta el elemento
    opacity: 0;   
    transition: visibility .3s ease-in-out, opacity 0.5s linear, height 0.5s linear; //lo oculta de modo desvanecido
    height: 0;

    @include tablet{
        display: flex;     
        align-items: center;
        opacity: 1;
        visibility: visible;
    }
    a{
        color: $blanco;
        text-decoration: none;
        font-size: 2.2rem;
        display: block;
        text-align: center;
        
        @include tablet {
            margin-right:  2rem;

            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    a:hover{
        color: $verde;
    }
}

//menu de hamburguesa

.mobile-menu{
   
 @include tablet{
    
    display: none;

 }

 img{
    width: 5rem;
 }
}

.mostrar{
    visibility: visible;
    opacity: 1;
    height: auto;
    // display: flex;
    // flex-direction: column;

    // @include tablet{        
    //     flex-direction: row;
    // }
}