.footer {
    padding: $separacion / 2 0 ;
    background-color: $grisOscuro;
    margin-bottom: 0;
    
    .contenedor-footer {
        
       padding-top: ($separacion / 2);
        
    }

    .navegacion{
        visibility: visible;
        opacity: 1;
        height: auto;
    }
    .copyright {
        color: $blanco;
        text-align: center;
    }
}