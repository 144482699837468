.contenido-tutor{
    display: flex;
    @media (max-width: 1024px) {
            justify-content: left;
            a{
                justify-content:right;
            }    
        
        }
        @media (max-width: 768px) {
            justify-content: center;
        }

        @media (max-width: 480px) {

            input, a{
                width: 100%;
            }
        }   
}

.contenido-izq{
    @media (min-width: 768px) {
        justify-content: right;
       
    }
    @media (max-width: 768px) {
        justify-content: center;
    }
}

.form-grid{

    @media (max-width: 480px) {

        display: grid;
        grid-template-columns: 50% 50%;

        td{
            padding: 2rem 0;
        }
        .form-block{
            padding: 0;
            display:grid;
        }
    }   
}
