.botonysemestre{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.escolar{
    display: flex;
    align-items: center;
    justify-content: center;
    fieldset{
        width: 20rem;
        height: 20rem;
        align-items: center;
        justify-content: center;
        margin: 5rem;
        border-radius: 6px;
        background-color: white;

    }
    
    fieldset:hover{
        cursor: pointer;
        
        border-width: 3px;
        border-color: black;
        background-color: rgb(215, 215, 214);
    }
}

.sombrearmaterias{
    div{
        display: unset;
        
        align-items: center;
        img{
            
            margin-top: -2rem;
           padding: 20px;
            //height: 160px;
        }
    }
}

.semestrevisible{
    
    display:none !important;
}
.grupovisible{
    display:none !important;
}

.calificacionesvisible{
    display:none !important;
}

.icono-calificaciones{
    padding: 0px;
    width:85%;
    margin-top: -15px;
    margin-left: 20px;
}

form{
    .sombreado{
            border-width: 3px;
            border-color: black;
            background-color: rgb(215, 215, 214);
            border-radius: 10px;
    }
    .materiaskardex{
        width: 35rem;
         option:hover{
        background-color: rgb(41, 156, 238);
        //font-size: 120%;
    }
    }
}

.ancho{
    width: 6rem;
}



.centrar-semestre{
    
    display: grid; 
    justify-content: center;
   
    optgroup{
        text-decoration: underline;
    }
    
    option{
        text-decoration: underline;
    }
    option:hover{
        background-color: rgb(41, 156, 238);
    }

    
}

.centrar-grupo{
    option{
        font-size: larger;
        
    }

 
    option:hover{
        background-color: rgb(41, 156, 238);
        font-size: 120%;
    }

    select{
        width: fit-content;
    }
    .materias{
        width: 50rem;
    }
   
}
//pagina materias

.boton-materias{
       // margin-top: 1rem;
        margin-bottom: 3rem;
        padding-top: 1rem;
        border-radius: 6px;
}


.ciclosemestre{
    text-decoration: underline;
}

.lista-grupos{
   
    display: flex;
    justify-content: center;
}

.botones-grupo{
    display: grid;
    margin-top: 10rem;
    height: 10rem;
    margin-right: 3.5rem;
}


//en calificaciones
table{
    .registros{
        
     font-size: 5rem;   
}
}

.mayusculas{
    text-transform: uppercase;
    font-weight:900;
    font-size: larger;
}


.listanombre{
min-width: 40rem;
}

.listamaterias{
    min-width: 30rem;
    margin-left: 0rem;
}

.sinmargen{
    padding: 0rem;
}



.form-calificacion{
    input:not([type="submit"]),//todos los input excepto --submit-- se van a ver afectados //
    textarea, select {
    padding: .5rem;
    margin-bottom: 0rem;
    
}
input.ancho-input{
    width: 4rem;
}
    }


///// pagina tutor.php
.descarga-formato{
    display: flex;
    justify-content: center;
}

///calificaciones deployment.php que vendra siendo calificaciones.php
.formulario{
    .form-calificaciones{
        input{
            display: flex;
            text-align: center;
            width: 11rem;
            font-size:xx-large
            
        }
    }
    .listamaterias{
        input{
            margin-left: -4rem;
        width: 30rem;
    }
}
}

