.dark-mode{
    .barra{
    .dark-mode-boton{       
        filter: invert(60%);
    }
    }
    background-color: darken($grisOscuro, 10);
    .contenedor{
    h1, h2, h3, h4, p{
        
            color: $gris;
        }
        
    }    
    .anuncio{
        background-color: darken($grisOscuro, 10);
        border: 1px solid $grisOscuro;

        }
        
    .contenido-anuncio{ 
            img{
            filter: invert(100%);
            }
        }
    

    .blog{
        h4{
            color: $blanco;
        }
    }

    .formulario{
        color: $gris;
        legend{
            color: $gris;
        }

        p{
            color:$gris;
        }
        select{
            background-color: $grisOscuro;
        }
      
        
        input:not([type="submit"]), textarea, select{
            background-color: $grisOscuro;
            color:$blanco;
            border: 1px solid $grisOscuro;
        }
    
        fieldset{
            background-color: $grisOscuro;
        }
        fieldset:hover{
            legend{
                color: black;
        }  
        }
    .iconos-anuncio{
        img{
            filter: invert(100%); 
        }
    }
    .iconoindex{
        filter: invert(100%);
    }
    tbody{
      
        .oscuro{
            
            color: white
         }
    }

    }}