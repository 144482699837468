.boton-amarillo{
    @include boton($amarillo, inline-block)        
    
}
.boton-rojo{
    @include boton($rojo, inline-block)        
    
}

.boton-amarillo-block{
    @include boton($amarillo, block)
        
    
}
.boton-amarillo-lista{
    background-color: $amarillo;
    color:$blanco;
    font-weight: $bold;
    text-decoration: none;
    padding: 5px;
    text-align: center;    
    display: block;
    border: none;
    margin: 5px, 0px, 5px, 0;
    
}
.boton-rojo-lista{
    background-color: $rojo;
    color:$blanco;
    font-weight: $bold;
    text-decoration: none;
    padding: 10px;
    text-align: center;    
    display: block;
    border: none;
    width: 100%;
    height: 40px;  
    &:hover{
        cursor: pointer;
    }
}
.boton-verde{
    @include boton($verde, inline-block);
    
}
.boton-rojo-block{
    @include boton($rojo, block);
    width: 100%;
}
.boton-verde-block{
    @include boton($verde, block);
}

.seleccionado{
    background-color: #71b100;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin-top: 2.5rem;
    padding: .5rem 3rem;
    text-align: center;
    text-decoration: none;
}

.nosubmit{
    background-color: #71b100;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin-top: 2.5rem;
    padding: .5rem 3rem;
    text-align: center;
    text-decoration: none;
    //border-radius: 4px;
}

.seleccionado-amarillo{
    background-color: $amarillo;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin-top: 2.5rem;
    padding: .5rem 3rem;
    text-align: center;
    text-decoration: none;
}