/** Media Queries **/
@mixin telefono {
    @media (min-width: #{$telefono}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin celmax {
    @media(max-width: va.$celmax){
        @content;
    }
}

@mixin grid($columnas, $espaciado ) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}

@mixin boton($bgcolor, $display){
    background-color: $bgcolor;
    color:$blanco;
    font-weight: $bold;
    text-decoration: none;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: 2rem;
    display: $display;
    border: none;

    
    &:hover{
        background-color: darken($bgcolor, 5);
        cursor: pointer;
    }

    
        
    
}