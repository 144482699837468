

@include tablet{
    .seccion-inferior{
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 2rem;
    }
}


.entrada-blog{
    @include tablet{    
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 2rem;
    }
    &:last-of-type{// el ultimo objeto no se aplica esa propiedad
        margin-bottom: 0;
    }
}

.texto-entrada{
  a{
    color: $negro;
   
  }  
  h4{
    margin: 0;
    line-height: 1.4;
    &::after{// para agregar un elemento
      content: '';
      display: block;
      width: 15rem;
      height: .5rem;
      background-color: $verde;
      margin-top: 1rem;
    }//fin del elemento

  }
  span{
    margin: 0;
    line-height: 1.4;
    color: $amarillo;
  }
  p{
    margin:6px;
    line-height: 1.4;
    color: $negro;
  }
}

.testimonial{
    margin-top: 3rem;
    background-color: $verde;
    border-radius: 20px;
    padding: 1.5rem;
    color: $blanco;
    font-size: 2.4rem;
    font-weight: 300;
    
    

  display: grid;
  grid-template-columns: 2;
  grid-template-rows: 3;

  .titular{
    color: $blanco;
    font-weight: 800;
    text-align: end;
    grid-column: 1 / 3;
    grid-row: 3;
   
    
   }

   .comilla{
    background-image: url(../img/comilla.svg);
    background-repeat: no-repeat;
    width: 6rem;
    height: 8rem;
    grid-area: 1 / 1;
    
    margin-top: .5rem;
    line-height: .1rem;
    color: $blanco;

   }

   .contenido{
    grid-area: 1 / 2;
    margin-top: .5rem;
    margin-left: 1rem;
    font-size: 6rem;
    font-weight: 700;
   }
   
  

  }
  .seccion-blog{// Para la pagina de blog//    
    grid-template-columns: 1fr;
    max-width: 80rem;
  }
.blog{
  h3{
    font-weight: 400;
  }
}