@include tablet{
    .iconos-nosotros{
        @include grid(3,2rem);
    }
}

.seccion{
    
    h1{
    color:black;    
    max-width: 100%;
    text-align: center;
    }
}

.icono{
    .icononuevo{
        display:flex;
        justify-content: center;
    }
   

    img{
        height: 10rem; // cambia el tamaño del icono
    }
    h3{
        text-transform: uppercase; //convierte a mayusculas
    }     
    
}
