
.no-webp .inicio{
    background-image: url(../img/portada.jpg);
  
}

.webp .inicio{
    background-image: url(../img/portada.webp);
}

.inicio{
    background-image: url(../img/portada.jpg);
    height: 30rem;
    background-position: center center;
    background-size: cover;   

      
    @include tablet {
        min-height: 85rem;
    }
    
    .contenido-header{
        @include tablet {
            min-height: 70rem;
        }
    }
    
}

.header{
    background-color: $grisOscuro;
    min-height: 10rem;
    
    .contenedor{
        .barra{
            .logo{
                width: 20rem;
                margin-top: -1rem;
                margin-bottom: -2rem;
            }
        }
    }

     
    
}

.contenido-header {
   display: flex;
   flex-direction: column;
   justify-content: space-between;


   
   
   }
   h1{
    color: $blanco;
    text-align: left;
    font-weight: $bold;
    max-width: 60rem;
   }


    

 


