@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/** Media Queries **/
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
}

p {
  color: #000000;
  font-size: 2rem;
}

.contenedor {
  width: 95%;
  max-width: 120rem;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

img,
picture {
  padding: 1rem;
  width: 100%;
  display: block;
}

h1, h2, h3 {
  font-family: "Lato", sans-serif;
  margin: 2.5rem 0;
  font-weight: 300;
  text-align: center;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 3.4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.6rem;
}

.seccion {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.contenido-centrado {
  max-width: 800px;
}

.alinear-derecha {
  display: flex;
  justify-content: flex-end;
}

.w-100 {
  width: 100%;
}

.boton-amarillo {
  background-color: #E08709;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2rem;
  display: inline-block;
  border: none;
}
.boton-amarillo:hover {
  background-color: #c77808;
  cursor: pointer;
}

.boton-rojo {
  background-color: rgb(191, 9, 9);
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2rem;
  display: inline-block;
  border: none;
}
.boton-rojo:hover {
  background-color: #a70808;
  cursor: pointer;
}

.boton-amarillo-block {
  background-color: #E08709;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2rem;
  display: block;
  border: none;
}
.boton-amarillo-block:hover {
  background-color: #c77808;
  cursor: pointer;
}

.boton-amarillo-lista {
  background-color: #E08709;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  display: block;
  border: none;
  margin: 5px, 0px, 5px, 0;
}

.boton-rojo-lista {
  background-color: rgb(191, 9, 9);
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 10px;
  text-align: center;
  display: block;
  border: none;
  width: 100%;
  height: 40px;
}
.boton-rojo-lista:hover {
  cursor: pointer;
}

.boton-verde {
  background-color: #71B100;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2rem;
  display: inline-block;
  border: none;
}
.boton-verde:hover {
  background-color: #619800;
  cursor: pointer;
}

.boton-rojo-block {
  background-color: rgb(191, 9, 9);
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2rem;
  display: block;
  border: none;
  width: 100%;
}
.boton-rojo-block:hover {
  background-color: #a70808;
  cursor: pointer;
}

.boton-verde-block {
  background-color: #71B100;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2rem;
  display: block;
  border: none;
}
.boton-verde-block:hover {
  background-color: #619800;
  cursor: pointer;
}

.seleccionado {
  background-color: #71b100;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  margin-top: 2.5rem;
  padding: 0.5rem 3rem;
  text-align: center;
  text-decoration: none;
}

.nosubmit {
  background-color: #71b100;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  margin-top: 2.5rem;
  padding: 0.5rem 3rem;
  text-align: center;
  text-decoration: none;
}

.seleccionado-amarillo {
  background-color: #E08709;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  margin-top: 2.5rem;
  padding: 0.5rem 3rem;
  text-align: center;
  text-decoration: none;
}

.dark-mode {
  background-color: #1a1a1a;
}
.dark-mode .barra .dark-mode-boton {
  filter: invert(60%);
}
.dark-mode .contenedor h1, .dark-mode .contenedor h2, .dark-mode .contenedor h3, .dark-mode .contenedor h4, .dark-mode .contenedor p {
  color: #e1e1e1;
}
.dark-mode .anuncio {
  background-color: #1a1a1a;
  border: 1px solid #333333;
}
.dark-mode .contenido-anuncio img {
  filter: invert(100%);
}
.dark-mode .blog h4 {
  color: #FFFFFF;
}
.dark-mode .formulario {
  color: #e1e1e1;
}
.dark-mode .formulario legend {
  color: #e1e1e1;
}
.dark-mode .formulario p {
  color: #e1e1e1;
}
.dark-mode .formulario select {
  background-color: #333333;
}
.dark-mode .formulario input:not([type=submit]), .dark-mode .formulario textarea, .dark-mode .formulario select {
  background-color: #333333;
  color: #FFFFFF;
  border: 1px solid #333333;
}
.dark-mode .formulario fieldset {
  background-color: #333333;
}
.dark-mode .formulario fieldset:hover legend {
  color: black;
}
.dark-mode .formulario .iconos-anuncio img {
  filter: invert(100%);
}
.dark-mode .formulario .iconoindex {
  filter: invert(100%);
}
.dark-mode .formulario tbody .oscuro {
  color: white;
}

/**UI**/
.no-webp .inicio {
  background-image: url(../img/portada.jpg);
}

.webp .inicio {
  background-image: url(../img/portada.webp);
}

.inicio {
  background-image: url(../img/portada.jpg);
  height: 30rem;
  background-position: center center;
  background-size: cover;
}
@media (min-width: 768px) {
  .inicio {
    min-height: 85rem;
  }
}
@media (min-width: 768px) {
  .inicio .contenido-header {
    min-height: 70rem;
  }
}

.header {
  background-color: #333333;
  min-height: 10rem;
}
.header .contenedor .barra .logo {
  width: 20rem;
  margin-top: -1rem;
  margin-bottom: -2rem;
}

.contenido-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  color: #FFFFFF;
  text-align: left;
  font-weight: 700;
  max-width: 60rem;
}

.footer {
  padding: 2.5rem 0;
  background-color: #333333;
  margin-bottom: 0;
}
.footer .contenedor-footer {
  padding-top: 2.5rem;
}
.footer .navegacion {
  visibility: visible;
  opacity: 1;
  height: auto;
}
.footer .copyright {
  color: #FFFFFF;
  text-align: center;
}

.logo {
  width: 30rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.derecha {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .derecha {
    align-items: flex-end;
  }
}
.derecha .dark-mode-boton {
  width: 5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  filter: invert(100%);
}
.derecha .dark-mode-boton:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .derecha .dark-mode-boton {
    margin-top: 0;
  }
}

.barra {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
}
@media (min-width: 768px) {
  .barra {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .barra .logo {
    width: 30rem;
    margin-left: 8rem;
    margin-right: 3rem;
  }
}

.navegacion {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease-in-out, opacity 0.5s linear, height 0.5s linear;
  height: 0;
}
@media (min-width: 768px) {
  .navegacion {
    display: flex;
    align-items: center;
    opacity: 1;
    visibility: visible;
  }
}
.navegacion a {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 2.2rem;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .navegacion a {
    margin-right: 2rem;
  }
  .navegacion a:last-of-type {
    margin-right: 0;
  }
}
.navegacion a:hover {
  color: #71B100;
}

@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu img {
  width: 5rem;
}

.mostrar {
  visibility: visible;
  opacity: 1;
  height: auto;
}

@media (min-width: 768px) {
  .iconos-nosotros {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
.seccion h1 {
  color: black;
  max-width: 100%;
  text-align: center;
}

.icono .icononuevo {
  display: flex;
  justify-content: center;
}
.icono img {
  height: 10rem;
}
.icono h3 {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .contenedor-anuncios {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
.anuncio {
  background-color: #e1e1e1;
  border: 1px solid #c8c8c8;
}
.anuncio .contenido-anuncio {
  padding: 2rem;
}
.anuncio .contenido-anuncio h3, .anuncio .contenido-anuncio p {
  margin: 0;
}
.anuncio .contenido-anuncio .precio {
  color: #71B100;
  font-weight: bold;
  font-size: 3rem;
}

.iconos-caracteristicas {
  display: flex;
  list-style: none;
  padding: 0;
}
.iconos-caracteristicas li {
  flex: 1;
  display: flex;
}
.iconos-caracteristicas li img {
  flex: 0 0 3rem;
  margin-right: 1rem;
}
.iconos-caracteristicas li p {
  font-weight: 900;
}

.centrar {
  display: block;
  justify-content: center;
}
@media (min-width: 480px) {
  .centrar {
    display: flex;
    justify-content: right;
  }
}
.centrar .boton-verde {
  display: block;
}

.no-webp .imagen-contacto {
  background-image: url("../img/portadanueva.jpg");
}

.webp .imagen-contacto {
  background-image: url("../img/portadanueva.webp");
}

.imagen-contacto {
  background-position: center center;
  background-size: cover;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imagen-contacto h2 {
  font-size: 4rem;
  font-weight: 900;
  color: black;
}
.imagen-contacto p {
  font-size: 1.8rem;
  color: black;
}

@media (min-width: 768px) {
  .seccion-inferior {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
  }
}
@media (min-width: 768px) {
  .entrada-blog {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 2rem;
  }
}
.entrada-blog:last-of-type {
  margin-bottom: 0;
}

.texto-entrada a {
  color: #000000;
}
.texto-entrada h4 {
  margin: 0;
  line-height: 1.4;
}
.texto-entrada h4::after {
  content: "";
  display: block;
  width: 15rem;
  height: 0.5rem;
  background-color: #71B100;
  margin-top: 1rem;
}
.texto-entrada span {
  margin: 0;
  line-height: 1.4;
  color: #E08709;
}
.texto-entrada p {
  margin: 6px;
  line-height: 1.4;
  color: #000000;
}

.testimonial {
  margin-top: 3rem;
  background-color: #71B100;
  border-radius: 20px;
  padding: 1.5rem;
  color: #FFFFFF;
  font-size: 2.4rem;
  font-weight: 300;
  display: grid;
  grid-template-columns: 2;
  grid-template-rows: 3;
}
.testimonial .titular {
  color: #FFFFFF;
  font-weight: 800;
  text-align: end;
  grid-column: 1/3;
  grid-row: 3;
}
.testimonial .comilla {
  background-image: url(../img/comilla.svg);
  background-repeat: no-repeat;
  width: 6rem;
  height: 8rem;
  grid-area: 1/1;
  margin-top: 0.5rem;
  line-height: 0.1rem;
  color: #FFFFFF;
}
.testimonial .contenido {
  grid-area: 1/2;
  margin-top: 0.5rem;
  margin-left: 1rem;
  font-size: 6rem;
  font-weight: 700;
}

.seccion-blog {
  grid-template-columns: 1fr;
  max-width: 80rem;
}

.blog h3 {
  font-weight: 400;
}

.formulario p {
  font-size: 1.4rem;
  color: #333333;
  margin: 2rem 0 0 0;
}
.formulario legend {
  font-size: 2rem;
  color: #333333;
}
.formulario label {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.formulario textarea {
  height: 20rem;
}
.formulario input:not([type=submit]),
.formulario textarea, .formulario select {
  padding: 1rem;
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.formulario .forma-contacto {
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formulario input[type=radio] {
  margin: 0;
  width: auto;
}
.formulario input[type=date] {
  width: 17rem;
}
.formulario select {
  width: 25rem;
}
.formulario .fecha-formulario {
  display: flex;
}
.formulario .fecha-formulario input[type=date] {
  width: 7rem;
}

.video {
  position: relative;
  max-height: 70rem;
  min-height: 50rem;
  overflow: hidden;
}
@media (max-width: 480px) {
  .video {
    max-height: 40rem;
    min-height: 30rem;
  }
}
@media (max-width: 1024px) {
  .video video {
    width: 140%;
  }
}
@media (min-width: 1024px) {
  .video video {
    width: 100%;
  }
}
.video .contenido-video h2 {
  font-weight: 400;
}

.botonysemestre {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.escolar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.escolar fieldset {
  width: 20rem;
  height: 20rem;
  align-items: center;
  justify-content: center;
  margin: 5rem;
  border-radius: 6px;
  background-color: white;
}
.escolar fieldset:hover {
  cursor: pointer;
  border-width: 3px;
  border-color: black;
  background-color: rgb(215, 215, 214);
}

.sombrearmaterias div {
  display: unset;
  align-items: center;
}
.sombrearmaterias div img {
  margin-top: -2rem;
  padding: 20px;
}

.semestrevisible {
  display: none !important;
}

.grupovisible {
  display: none !important;
}

.calificacionesvisible {
  display: none !important;
}

.icono-calificaciones {
  padding: 0px;
  width: 85%;
  margin-top: -15px;
  margin-left: 20px;
}

form .sombreado {
  border-width: 3px;
  border-color: black;
  background-color: rgb(215, 215, 214);
  border-radius: 10px;
}
form .materiaskardex {
  width: 35rem;
}
form .materiaskardex option:hover {
  background-color: rgb(41, 156, 238);
}

.ancho {
  width: 6rem;
}

.centrar-semestre {
  display: grid;
  justify-content: center;
}
.centrar-semestre optgroup {
  text-decoration: underline;
}
.centrar-semestre option {
  text-decoration: underline;
}
.centrar-semestre option:hover {
  background-color: rgb(41, 156, 238);
}

.centrar-grupo option {
  font-size: larger;
}
.centrar-grupo option:hover {
  background-color: rgb(41, 156, 238);
  font-size: 120%;
}
.centrar-grupo select {
  width: fit-content;
}
.centrar-grupo .materias {
  width: 50rem;
}

.boton-materias {
  margin-bottom: 3rem;
  padding-top: 1rem;
  border-radius: 6px;
}

.ciclosemestre {
  text-decoration: underline;
}

.lista-grupos {
  display: flex;
  justify-content: center;
}

.botones-grupo {
  display: grid;
  margin-top: 10rem;
  height: 10rem;
  margin-right: 3.5rem;
}

table .registros {
  font-size: 5rem;
}

.mayusculas {
  text-transform: uppercase;
  font-weight: 900;
  font-size: larger;
}

.listanombre {
  min-width: 40rem;
}

.listamaterias {
  min-width: 30rem;
  margin-left: 0rem;
}

.sinmargen {
  padding: 0rem;
}

.form-calificacion input:not([type=submit]),
.form-calificacion textarea, .form-calificacion select {
  padding: 0.5rem;
  margin-bottom: 0rem;
}
.form-calificacion input.ancho-input {
  width: 4rem;
}

.descarga-formato {
  display: flex;
  justify-content: center;
}

.formulario .form-calificaciones input {
  display: flex;
  text-align: center;
  width: 11rem;
  font-size: xx-large;
}
.formulario .listamaterias input {
  margin-left: -4rem;
  width: 30rem;
}

.contenido-tutor {
  display: flex;
}
@media (max-width: 1024px) {
  .contenido-tutor {
    justify-content: left;
  }
  .contenido-tutor a {
    justify-content: right;
  }
}
@media (max-width: 768px) {
  .contenido-tutor {
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .contenido-tutor input, .contenido-tutor a {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .contenido-izq {
    justify-content: right;
  }
}
@media (max-width: 768px) {
  .contenido-izq {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .form-grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .form-grid td {
    padding: 2rem 0;
  }
  .form-grid .form-block {
    padding: 0;
    display: grid;
  }
}

.nosotros h1 {
  font-weight: 400;
}
.nosotros h4 {
  font-weight: 400;
}
@media (min-width: 768px) {
  .nosotros {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5rem;
  }
  .nosotros .titulo {
    grid-column: 1/3;
  }
  .nosotros .imagen {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .nosotros .contenido {
    margin-top: -5rem;
    grid-column: 2/3;
    grid-row: 2/3;
    padding-left: 2rem;
  }
}

.footer-anuncios {
  margin-top: 0;
  padding-top: 0;
}

.contenedor-anuncio {
  max-width: 80rem;
}

.iconos-anuncio {
  max-width: 30rem;
  margin-left: 7rem;
}

.alerta {
  padding: 0.5rem;
  text-align: center;
  color: #FFFFFF;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1rem;
}
.alerta.error {
  background-color: rgb(191, 9, 9);
}

.exito {
  background-color: #71B100;
  padding: 0.5rem;
  text-align: center;
  color: #FFFFFF;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1rem;
}

.advertencia {
  background-color: #E08709;
}

table.propiedades {
  margin-top: 4rem;
  width: 100%;
  border-spacing: 0;
}
table.propiedades thead {
  background-color: #71B100;
}
table.propiedades thead th {
  color: #FFFFFF;
  padding: 2rem;
}
table.propiedades tbody td {
  padding-left: 5rem;
  border-bottom: 2px solid #e1e1e1;
}
table.propiedades .imagen-tabla {
  width: 10rem;
}

.imagen-pequeña {
  width: 20rem;
}

.opciones {
  font-size: medium;
  display: flex;
  gap: 1rem;
}
.opciones label {
  text-transform: capitalize;
}