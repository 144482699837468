.nosotros{
    h1{
        font-weight: 400;
    }
    h4{
        font-weight: 400;
    }
    @include tablet{
  
    @include grid(2, 2rem);
    //grid-template-columns: 1fr 1fr;
    //grid-template-rows: 3;
    margin-bottom: 5rem;

    .titulo{
        grid-column: 1 / 3;
       
    }

    .imagen{
        grid-column:  1 / 2;
        grid-row: 2 / 3;
    }
    
    .contenido{
        margin-top: -5rem;
        
        grid-column: 2 /3;
        grid-row: 2 / 3;
        padding-left: 2rem;
    }
}
}