.formulario{

    p{
       font-size: 1.4rem;
       color: $grisOscuro;
       margin: 2rem 0 0 0 ;
    }

    legend{
        font-size: 2rem;
        color: $grisOscuro;
    }

    label{
        font-weight: $bold;
        text-transform: uppercase;
        display: block; // acomoda los elementos en forma vertical//
    }
    textarea{
        height: 20rem;
    }

    input:not([type="submit"]),//todos los input excepto --submit-- se van a ver afectados //
        textarea, select {
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: $blanco;
        border:1px solid $gris;
        border-radius: 1rem;
        margin-bottom: 2rem;
    }
    //Acomoda el check //
    .forma-contacto{
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
     //Acomoda el check //
    input[type="radio"]{
        margin: 0;
        width: auto;
    }

  input[type=date]{ //solo el tipo date
        width: 17rem;
  }

  select{
    width: 25rem;
  }

  .fecha-formulario{
    display: flex;
    input[type=date]{ //solo el tipo date
        width: 7rem;
  }
  }
}

