.no-webp .imagen-contacto{
 background-image: url('../img/portadanueva.jpg');
}

.webp .imagen-contacto{
    background-image: url('../img/portadanueva.webp');
}

.imagen-contacto{
    background-position: center center;
    background-size: cover;
    padding: $separacion * 2 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        font-size: 4rem;
        font-weight: $black;
        color:black;
    }
    p{
        font-size: 1.8rem;
        color:black;
    }
}