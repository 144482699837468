@include tablet {
    .contenedor-anuncios {
        @include grid(3,2rem);
    }

    
}

.anuncio{
    background-color: $gris;
    border: 1px solid darken($gris, 10);

.contenido-anuncio{
    padding:2rem;
    h3, p {
        margin: 0;
    }
    .precio{
        color: $verde;
        font-weight: bold;
        font-size: 3rem;
    }
}    
}

.iconos-caracteristicas{
    display: flex;
    list-style: none;
    padding: 0;
    
    li{
        flex: 1;
        display: flex;
        
        img{
            flex: 0 0 3rem;
            margin-right: 1rem;
        }
        
        p{
            font-weight: $black;
        }
    }

    
}
 .centrar{


    @include telefono{ //si  mide mas que un telefono
        display: flex;
        justify-content: right;
        
   
    }
    display: block;
    justify-content: center;
    
    .boton-verde{
       display: block;
        
    }

 }
