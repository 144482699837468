.alerta{
    padding: .5rem;
    text-align: center;
    color: $blanco;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem;
    
    &.error{
        background-color: $rojo;
    }
     
    
    
}
.exito{
    background-color: $verde;
    padding: .5rem;
    text-align: center;
    color: $blanco;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem;
}

.advertencia{
    background-color: $amarillo;
    
}

table.propiedades{
    margin-top: 4rem;
    width:100%;
    border-spacing: 0;

    thead{
        background-color: $verde;
        th{
            color: $blanco;
            padding:2rem;
        }        
    }
    tbody{
      
        td{
           padding-left : 5rem; 
           border-bottom: 2px solid $gris;
        }
    }

    .imagen-tabla{
        width: 10rem;
    }
}

.imagen-pequeña{
    width: 20rem;
}

.opciones{

    label{
        text-transform: capitalize;
    }
    
    font-size:medium;
    display: flex;
    gap: 1rem;
}



