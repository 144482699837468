

.video{
    @media (max-width: $telefono)  {
        max-height: 40rem;
        min-height: 30rem;
     }
    position: relative; // para poner el texto encima del video
    max-height: 70rem;
    min-height: 50rem;
    overflow: hidden;

    video{
 
     @media (max-width: $desktop)  {
        width: 140%;
 
     }
     @media (min-width: $desktop)  {
        width: 100%;
 
     }

}

.contenido-video{
    h2{
        font-weight: 400;
        
    }
}
    

}