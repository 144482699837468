.seccion {
    margin-top: calc($separacion / 2);
    margin-bottom: calc($separacion / 2);
}
.contenido-centrado {
    max-width: 800px;
}


.alinear-derecha{
    
    display: flex;
    justify-content: flex-end;
   
}

.w-100{
    width: 100%;
}
